import React, { useState, useContext, useRef } from "react";
import styles from "./asset.module.scss";
import HierarchyContext from "components/hierarchy/context";
import ActivityLog from "../activityLog/activityLog";
import Project from "../project/project";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import Summary from "../summary/summary";
import { dict, currency, withMemo } from "helper/global";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid.js";
import MultiEditor from "components/multi-editor/multi-editor";

function Component(props) {
  //
  // useStore

  const initialState = { id: null, nodeId: null };
  const [state, setState] = useState(initialState);
  const [editor, setEditor] = useState(false);
  const loadOptions = useRef(null);

  // useContext

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  function onEditor() {
    setEditor(true);
  }

  function onLoadOptions(e) {
    loadOptions.current = e;
  }

  // render

  function activityLog() {
    return state.id && <ActivityLog id={state.id} selectedRowsData={selectedRowsData} assetId={state.assetId} />;
  }

  function summaryRender() {
    return state.id && <Summary scenarioNodeId={state.id} nodeId={state.nodeId} assetId={state.assetId} nodeTypeId={state.nodeTypeId} />;
  }

  function detailsRender() {
    return (
      <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
        <Item title={dict("Summary")} render={summaryRender} />
        <Item title={dict("Activity")} render={activityLog} />
        {/* <Item title={dict("Project")} render={projectRender} /> */}
      </TabPanel>
    );
  }

  function gridRender() {
    return (
      <div className={styles.main}>
        <div className={styles.item1}>
          <Asset
            selectedRowsData={selectedRowsData}
            filter={filter}
            onSelectionChanged={onSelectionChanged}
            onEditor={onEditor}
            onLoadOptions={onLoadOptions}
          />
        </div>
        <div className={styles.item2}>{state && detailsRender()}</div>
      </div>
    );
  }

  return (
    selectedRowsData.length > 0 && (
      <MultiEditor
        options={{
          url: `${url}/multinode/bynodes`,
          loadOptions: loadOptions.current,
          params: {
            ids: selectedRowsData.map((i) => i.id),
            filterExpression: filter,
          },
        }}
        visible={editor}
        onHiding={() => {
          setEditor(false);
        }}
      >
        {gridRender()}
      </MultiEditor>
    )
  );
}

// redux

export default Component;

function mapStateToProps(state) {
  return {
    multiEditNode: !!state.user.accessAdmin,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

const Asset = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withMemo(
    (props) => {
      // variables

      var disabledForProgram = [
        "type",
        "size",
        "material",
        "manufacturer",
        "model",
        "count",
        "installYear",
        "value",
        "usefulLife",
        "installYear",
      ];

      //
      // stores

      const assetStore = {
        store: new CustomStore({
          key: "id",
          load: (loadOptions) => {
            props.onLoadOptions(loadOptions);
            return exporting.current
              ? request({
                  url: `${url}/scenarionode/download`,
                  loadOptions: loadOptions,
                  params: {
                    ids: props.selectedRowsData.map((i) => i.id),
                    filterExpression: props.filter,
                    types: ["Asset", "SuperAsset", "Program"],
                  },
                })
              : request({
                  url: `${url}/scenarionode`,
                  loadOptions: loadOptions,
                  params: {
                    ids: props.selectedRowsData.map((i) => i.id),
                    filterExpression: props.filter,
                    types: ["Asset", "SuperAsset", "Program"],
                  },
                });
          },
          update: (_, data) => {
            return request({ url: `${url}/scenarionode`, method: "Put", data: data });
          },
        }),
      };

      const strategyStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/strategy`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/strategy/${id}` });
          },
        }),
      };

      const costStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/cost`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/cost/${id}` });
          },
        }),
      };

      // hooks

      const exporting = useRef(false);

      // event handlers

      function onExporting(e) {
        e.component.columnOption("id", "visible", true);
        e.component.columnOption("parent", "visible", true);
        e.component.columnOption("operation", "visible", true);
        exporting.current = true;
      }

      function onExported(e) {
        e.component.columnOption("id", "visible", false);
        e.component.columnOption("parent", "visible", false);
        e.component.columnOption("operation", "visible", false);
        exporting.current = false;
      }

      function onSelectionChanged(e) {
        props.onSelectionChanged && props.onSelectionChanged(e);
      }

      async function onUploaded(fileId) {
        props.upload({ name: "UPLOAD_SCENARIONODE", url: `${url}/scenarionode/upload/file/${fileId}` });
      }

      let nodeType = null;

      function onEditingStart(e) {
        nodeType = e.data.nodeType;
      }

      function customizeItem(e) {
        if (nodeType === "Folder") {
          if (["cof", "r", "usefulLife"].includes(e.dataField)) {
            e.visible = false;
          }
        }
      }

      function onInitNewRow(e) {
        e.data.cof = 1;
        e.data.r = 1;
      }

      function onCellPrepared(e) {
        if (e.rowType === "data" && disabledForProgram.some((i) => i === e.column.dataField) && e.data.nodeTypeId === 3) {
          e.cellElement.style.background = "#44570E";
          e.cellElement.style.color = "#292929";
        }
      }

      function onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
          if (disabledForProgram.some((i) => i === e.dataField)) {
            if (e.row.data.nodeTypeId === 3) {
              e.editorOptions.disabled = true;
            }
          }
        }
      }

      // render

      function onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          visible: props.multiEditNode,
          options: {
            hint: "MultiEdit",
            icon: "fas fa-edit",
            onClick: props.onEditor,
          },
        });
      }

      return (
        <ProgressPanel name={"UPLOAD_SCENARIONODE"}>
          <div className={styles.main}>
            <Grid
              title={"Asset"}
              dataSource={assetStore}
              selection={{ mode: "single" }}
              allowAdding={false}
              allowDeleting={false}
              onUploaded={onUploaded}
              onSelectionChanged={onSelectionChanged}
              onExported={onExported}
              onExporting={onExporting}
              onEditingStart={onEditingStart}
              onInitNewRow={onInitNewRow}
              customizeItem={customizeItem}
              onCellPrepared={onCellPrepared}
              onEditorPreparing={onEditorPreparing}
              onToolbarPreparing={onToolbarPreparing}
              allowExpanding={true}
            >
              <Column caption={"Id"} visible={false} dataField={"id"} width={100} formItem={{ visible: false }} />
              <Column
                caption={"Operation"}
                dataField={"operation"}
                calculateCellValue={() => "Update"}
                visible={false}
                formItem={{ visible: false }}
              />
              <Column
                caption={"Parent"}
                dataField={"parent"}
                allowEditing={false}
                width={200}
                visible={false}
                formItem={{ visible: false }}
              />
              <Column caption={"Node Type"} dataField={"nodeTypeId"} visible={false} formItem={{ visible: false }} />
              <Column caption={"AssetId"} dataField={"name"} allowEditing={false} width={200} />
              <Column caption={"Description"} dataField={"description"} allowEditing={false} width={400} />
              <Column caption={"Location"} dataField={"location"} dataType={"string"} allowEditing={false} width={200} />
              <Column caption={"Ownership"} dataField={"ownership"} width={200} allowEditing={false} />
              <Column caption={"Class"} dataField={"class"} allowEditing={false} width={200} />
              <Column caption={"Type"} dataField={"type"} allowEditing={false} width={200} />
              <Column caption={"Size"} dataField={"size"} allowEditing={false} width={200} />
              <Column caption={"Material"} dataField={"material"} allowEditing={false} width={200} />
              <Column caption={"Manufacturer"} dataField={"manufacturer"} allowEditing={false} width={200} />
              <Column caption={"Model"} dataField={"model"} dataType={"string"} allowEditing={false} width={200} />
              <Column caption={"Count"} dataField={"count"} allowEditing={false} width={200} />
              <Column caption={"Install Year"} dataField={"installYear"} dataType={"number"} allowEditing={false} width={200} />
              {/* <Column
                caption={"Useful Life"}
                dataField={"usefulLife"}
                dataType={"number"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              /> */}
              <Column
                caption={"Potential Useful Life"}
                dataField={"potentialUsefulLife"}
                dataType={"number"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              />
              {/* <Column
                caption={"Effective Useful Life"}
                dataField={"effectiveUsefulLife"}
                dataType={"number"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              /> */}
              <Column caption={"Strategy"} dataField={"strategyId"} calculateDisplayValue="strategy" width={200}>
                <Lookup dataSource={strategyStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
              </Column>

              <Column caption={"Cost"} dataField={"costId"} calculateDisplayValue="cost" width={200}>
                <Lookup dataSource={costStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
              </Column>
              <Column
                caption={"Cost Override"}
                dataField={"costOverride"}
                dataType={"number"}
                width={100}
                format={`${currency()}#,###`}
                formItem={{ editorOptions: { format: `${currency()}#,###`, showClearButton: true } }}
                // customizeText={(e) => {
                //   if (e.value === "null") return null;
                //   console.log("EEEEEEEEE", e);
                // }}
              >
                {/* <NumericRule /> */}
              </Column>

              {/* <Column
                caption={"Pof Override"}
                dataField={"pofOverride"}
                dataType={"number"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              /> */}
              <Column
                caption={"Cof"}
                dataField={"cof"}
                dataType={"number"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              />
              <Column
                caption={"R"}
                dataField={"r"}
                dataType={"number"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              />
              <Column
                caption={"Standby"}
                dataField={"standby"}
                dataType={"number"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              />
              <Column
                caption={"Last Year"}
                dataField={"lastYear"}
                dataType={"number"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              />
              <Column caption={"Override Activity Cost"} dataField={"overrideActivityCost"} dataType={"boolean"} width={100} />
              <Column visible={false} formItem={{ itemType: "empty" }} />
              <Column
                caption={"Notes"}
                dataField={"notes"}
                dataType={"string"}
                width={400}
                allowEditing={false}
                formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
              />
            </Grid>
          </div>
        </ProgressPanel>
      );
    },
    ["selectedRowsData", "filter"]
  )
);
