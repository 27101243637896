import React, { useState, useMemo } from "react";
import styles from "./activityLog.module.scss";
import { Popup } from "devextreme-react/popup";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import { dict } from "helper/global";
import ImgTileView from "components/tileview/tileview";
import DocTileView from "components/doc-tileview/doc-tileview";
import { Button } from "devextreme-react/button";
import ActivityLog from "./grid/grid";
import { url } from "config.js";

function Component(props) {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handler

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  //render

  function imageRender() {
    return (
      <ImgTileView
        title={state.name}
        urls={{
          read: `${url}/activitylogimage/activitylog/images/${state.id}`,
          delete: `${url}/activitylogimage`,
          upload: `${url}/activitylogimage/blob/activitylog/${state.id}`,
        }}
      />
    );
  }

  function documentRender() {
    return (
      <DocTileView
        title={state.name}
        allowEditing={true}
        urls={{
          read: `${url}/activitylogdocument/activitylog/${state.id}`,
          delete: `${url}/activitylogdocument`,
          upload: `${url}/activitylogdocument/blob/activitylog/${state.id}`,
        }}
      />
    );
  }

  function tabsRender() {
    return (
      <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
        <Item title={dict("Image")} render={imageRender} />
        <Item title={dict("Document")} render={documentRender} />
      </TabPanel>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.grid}>
        <ActivityLog data={props.data} onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.tabs}>{state.id && tabsRender()}</div>
    </div>
  );
}

export default Component;
