import React from "react";
import styles from "./user.module.scss";
import Grid from "components/data-grid/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Editing, FilterRow, Lookup, Popup, Position } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";

function Component(props) {
  //
  // stores

  const kolUserStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/koluser`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/koluser`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/koluser`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/koluser/${id}`, method: "Delete" });
      },
    }),
  };

  const userStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({
          url: `${url}/user/all`,
          loadOptions: loadOptions,
        });
      },
      byKey: function (id) {
        return request({ url: `${url}/user/${id}` });
      },
    }),
  };

  const strategyStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/strategy`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/strategy/${id}` });
      },
    }),
  };

  const departmentStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/department`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/department/${id}` });
      },
    }),
  };

  // render

  return (
    <div className={styles.main}>
      <Grid
        dataSource={kolUserStore}
        title={"User"}
        editingMode={"row"}
        selection={{ mode: "single" }}
        rowNumbering={true}
        allowDownloading={false}
      >
        <Editing mode={"popup"} allowUpdating={true} allowDeleting={true}>
          <Popup title="User" showTitle={true} width={700} height={700}>
            <Position my="center" at="center" of={window} />
          </Popup>
        </Editing>
        <FilterRow visible={true} />
        {/* <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} /> */}
        <Column caption={"UserName"} dataField={"id"} sortOrder={"desc"}>
          <Lookup dataSource={userStore} valueExpr={"id"} displayExpr="userName" />
          <RequiredRule />
        </Column>
        <Column caption={"Department"} dataField={"departmentId"}>
          <Lookup dataSource={departmentStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
        </Column>
        <Column caption={"Strategy"} dataField={"strategyId"} calculateDisplayValue="strategy">
          <Lookup dataSource={strategyStore} valueExpr={"id"} displayExpr="name" />
        </Column>
        <Column caption={"PortalId"} dataField={"offlinePortal"} visible={false} />
        <Column caption={"PortalUrl"} dataField={"portalUrl"} visible={false} />
        <Column caption={"ClientId"} dataField={"clientId"} visible={false} />
        <Column caption={"RedirectUrl"} dataField={"redirectUrl"} visible={false} />
        <Column caption={"UserName"} dataField={"gisUserName"} visible={false} />
        <Column
          caption={"Password"}
          dataField={"gisPassword"}
          visible={false}
          formItem={{
            visible: true,
            editorOptions: {
              mode: "password",
            },
          }}
        />
        <Column caption={"Image Width"} dataField={"imageWidth"} dataType="number" />
        <Column caption={"Image Height"} dataField={"imageHeight"} dataType="number" />
        <Column caption={"Desired Accuracy"} dataField={"accuracy"} />
        <Column caption={"Backup"} dataField={"backup"} dataType="boolean" />
      </Grid>
    </div>
  );
}

export default Component;
