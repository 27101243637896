import React, { useState } from "react";
import styles from "./activityLog.module.scss";
import Grid from "./grid/grid";
import { url } from "config.js";
import ImageBox from "components/image-box/image-box";
import TileView from "components/tileview/tileview";

function Component(props) {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handler

  function onSelectionChanged(e) {
    setState(initialState);
    setState(e.selectedRowsData[0] || initialState);
  }

  //
  // render

  // function tileRender() {
  //   return (
  //     <div>
  //       <TileView
  //         id={props.assetId}
  //         urls={{
  //           read: `${url}/activitylogimage/activityLogId/${props.activityLogId}`,
  //           delete: `${url}/activitylogimage`,
  //           upload: `${url}/activitylogimage/blob/asset/${props.activityLogId}`,
  //         }}
  //         title={props.assetDescription}
  //         render={render}
  //         refresh={onRefresh}
  //       ></TileView>
  //     </div>
  //   );
  // }

  return (
    <div className={styles.main}>
      <div className={styles.grid}>
        <Grid id={props.id} selectedRowsData={props.selectedRowsData} onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.gallery}>{state.id && <ImageBox url={`${url}/activitylogimage/activitylog/images/${state.id}`} />}</div>
    </div>
  );
}

export default Component;
