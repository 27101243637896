import React from "react";
import "./klient.scss";
import { Route, Switch, withRouter } from "react-router-dom";
import Layout from "./layout/layout";
import DataLookup from "./data/lookup/lookup";
import DataAsset from "./data/asset/asset";
import DataFile from "./data/file/file";
import { navigation } from "./navigation";
import { connect } from "react-redux";
import { dict, withMemo } from "helper/global";
import Query from "./report/query/query";
import Home from "./home/home.js";
import Apps from "./apps/apps";
import Classification from "./catalog/classification/classification";
import PlanLookup from "./plan/lookup/lookup";
import PlanInput from "./plan/input/input";
import PlanOutput from "./plan/output/output";
import PlanProject from "./plan/project/project";
import UserSettings from "./layout/settings/user-setting";
import AdminAccess from "./admin/access/access";
import AdminSettings from "./admin/setting/setting";
import AdminAudit from "./admin/audit/audit";
import ManageLookup from "./manage/lookup/lookup";
import ManageInput from "./manage/input/input";
import ManageOutput from "./manage/output/output";
import FinanceLookup from "./finance/lookup/lookup";
import FinanceInput from "./finance/input/input";
import FinanceOutput from "./finance/output/output";
import FinanceTools from "./finance/tools/tools";
import Ticket from "klient/ticket/ticket";
import ConnectInput from "./connect/input/input";
import ConnectOutput from "./connect/output/output";
import Collect from "./collect/user";

function Component(props) {
  //
  // constants

  const accessVisible = props.user.accessAccess;
  const settingsVisible = props.user.accessScenario || props.user.accessDisplay || props.user.accessGis;
  const auditLogVisible = props.user.accessAdmin;
  const connectVisible = props.user.accessConnect;
  const collectVisible = props.user.accessCollect;
  // functions

  function setNavigation() {
    const nav = JSON.parse(JSON.stringify(navigation));
    let admin = nav.find((i) => i.text === "Admin");
    admin.visible = accessVisible || settingsVisible;
    let accessControl = admin.items.find((i) => i.text === "Access");
    let settings = admin.items.find((i) => i.text === "Settings");
    let audit = admin.items.find((i) => i.text === "Audit Logs");
    accessControl.visible = accessVisible;
    settings.visible = settingsVisible;
    audit.visible = auditLogVisible;

    let connect = nav.find((i) => i.text === "Connect");
    connect.visible = connectVisible;

    let collect = nav.find((i) => i.text === "Collect");
    collect.visible = collectVisible;

    // let development = nav.find((i) => i.text === "Development");
    // development.visible = process.env.REACT_APP_CUSTOM_NODE_ENV === "Development";
    if (props.app.length > 0) {
      var items = props.app.map((i) => {
        return { text: i.description, path: `/app/${i.name}`, icon: i.icon };
      });
      nav.push({
        text: "Apps",
        icon: "fad fa-rocket-launch",
        items: items,
      });
    }
    nav.forEach((i) => {
      i.text = dict(i.text);
      if (i.items) i.items.forEach((j) => (j.text = dict(j.text)));
    });
    return nav;
  }

  // render

  return <MemorizedLayout setNavigation={setNavigation} />;
}

function mapStateToProps(state) {
  return {
    user: state.user,
    app: state.app,
  };
}

export default withRouter(connect(mapStateToProps, null)(Component));

const MemorizedLayout = withMemo((props) => {
  return (
    <Layout navigation={props.setNavigation()}>
      <Switch>
        <Route exact path={["/klient", "/klient/home"]} component={Home} />

        <Route exact path={"/klient/catalog"} component={Classification} />
        <Route path={"/klient/catalog/classification"} component={Classification} />

        <Route exact path={"/klient/data"} component={DataLookup} />
        <Route path={`/klient/data/lookup`} component={DataLookup} />
        <Route path={`/klient/data/asset`} component={DataAsset} />
        <Route path={`/klient/data/file`} component={DataFile} />

        <Route exact path={"/klient/manage"} component={ManageLookup} />
        <Route path={"/klient/manage/lookup"} component={ManageLookup} />
        <Route path={"/klient/manage/input"} component={ManageInput} />
        <Route path={"/klient/manage/output"} component={ManageOutput} />

        <Route exact path={"/klient/plan"} component={PlanInput} />
        <Route path={"/klient/plan/lookup"} component={PlanLookup} />
        <Route path={"/klient/plan/input"} component={PlanInput} />
        <Route path={"/klient/plan/output"} component={PlanOutput} />
        <Route path={"/klient/plan/project"} component={PlanProject} />

        <Route exact path={"/klient/finance"} component={FinanceLookup} />
        <Route path={"/klient/finance/lookup"} component={FinanceLookup} />
        <Route path={"/klient/finance/input"} component={FinanceInput} />
        <Route path={"/klient/finance/output"} component={FinanceOutput} />
        <Route path={"/klient/finance/tools"} component={FinanceTools} />

        <Route exact path={"/klient/report"} component={Query} />
        <Route path={"/klient/report/query"} component={Query} />

        <Route exact path={"/klient/connect"} component={ConnectInput} />
        <Route path={"/klient/connect/input"} component={ConnectInput} />
        <Route path={"/klient/connect/output"} component={ConnectOutput} />

        <Route exact path={"/klient/collect"} component={Collect} />

        <Route exact path={"/klient/admin"} component={AdminAccess} />
        <Route path={"/klient/admin/access"} component={AdminAccess} />
        <Route path={"/klient/admin/setting"} component={AdminSettings} />
        <Route path={"/klient/admin/audit"} component={AdminAudit} />

        <Route path={"/klient/user/setting"} component={UserSettings} />
        <Route path={"/klient/app"} component={Apps} />
        <Route path={"/klient/ticket"} component={Ticket} />
      </Switch>
    </Layout>
  );
}, []);
