import React, { useState, useRef } from "react";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import styles from "./image.module.scss";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import TileView from "components/tileview/tileview";
import { dict } from "helper/global";
import { withMemo } from "helper/global";
import { NumericRule } from "devextreme-react/validator";

function Component(props) {
  //
  // useRef
  const exporting = useRef(false);

  // store

  const assetImageStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (exporting.current) {
          return request({
            url: `${url}/activitylogimage/download`,
            loadOptions: loadOptions,
            params: { ids: props.selectedRowsData.map((i) => i.id) },
          });
        } else {
          if (!props.activityLogId) return Promise.resolve({ data: [] });
          return request({ url: `${url}/activitylogimage/activitylog/${props.activityLogId}`, loadOptions: loadOptions });
        }
      },
      insert: (data) => {
        return request({ url: `${url}/activitylogimage`, method: "Post", data: { ...data, activityLogId: props.activityLogId } });
      },
      update: (_, data) => {
        return request({ url: `${url}/activitylogimage`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/activitylogimage/${id}`, method: "Delete" });
      },
      onModified: () => {
        onRefresh();
      },
    }),
  };

  // useState

  const [render, setRender] = useState(0);

  // event handlers

  function onRefresh() {
    setRender((render) => render + 1);
  }

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_ACTIVITYLOG_IMAGE", url: `${url}/activitylogimage/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("activityLogId", "visible", true);
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("urlImage", "visible", false);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("activityLogId", "visible", false);
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("urlImage", "visible", true);
    exporting.current = false;
  }

  // render

  const gridRender = () => {
    return (
      <ProgressPanel name={"UPLOAD_ACTIVITYLOG_IMAGE"} onComplete={onRefresh}>
        <Grid
          title={"Image"}
          showTitle={false}
          dataSource={assetImageStore}
          onUploaded={onUploaded}
          onExported={onExported}
          onExporting={onExporting}
          allowExpanding={true}
        >
          <Column visible={false} dataField={"id"} caption="Id" formItem={{ visible: false }} />
          <Column visible={false} dataField={"activityLogId"} caption="ActivityLogId" formItem={{ visible: false }} />
          <Column
            caption={"Operation"}
            dataField={"operation"}
            calculateCellValue={() => "Update"}
            width={100}
            visible={false}
            formItem={{ visible: false }}
          />
          <Column
            dataField={"urlImage"}
            caption={""}
            width={125}
            allowFiltering={false}
            allowSorting={false}
            cellRender={imageRender}
            formItem={{ visible: false }}
          />
          <Column dataField="url" caption={"Url"} allowSorting={false} allowFiltering={false} formItem={{ colSpan: 2 }} />
          <Column dataField="nullOrder" visible={false} sortIndex={1} formItem={{ visible: false }} />
          <Column caption={"Order"} dataField={"order"} dataType={"number"} width={100} allowFiltering={false} sortIndex={2}>
            <NumericRule />
          </Column>
        </Grid>
      </ProgressPanel>
    );
  };

  function imageRender(e) {
    return (
      <div>
        <img src={e.data.url} height={50} alt={""} />
      </div>
    );
  }

  function tileRender() {
    return (
      <div>
        <TileView
          id={props.activityLogId}
          urls={{
            read: `${url}/activitylogimage/activitylog/images/${props.activityLogId}`,
            delete: `${url}/activitylogimage`,
            upload: `${url}/activitylogimage/blob/activitylog/${props.activityLogId}`,
          }}
          title={props.assetDescription}
          render={render}
          refresh={onRefresh}
        ></TileView>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
        <Item title={dict("Tile")} render={tileRender} />
        <Item title={dict("Grid")} render={gridRender} />
      </TabPanel>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(withMemo(Component, ["activityLogId", "selectedHierarchyNodes"]));
